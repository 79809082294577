
import Vue from "vue";
import ProjectCard from "../components/ProjectCard.vue";

export default Vue.extend({
  name: "Projects",
  components: {
    ProjectCard,
  },
  data() {
    return {
      projects: [
        {
          name: "Pretty ls",
          github: "pretty-ls",
          image:
            "https://user-images.githubusercontent.com/20462187/128727289-29264820-68fb-44c6-8454-5b0ab20c350f.png",
          icons: ["mdi-powershell"],
        },
        {
          name: "Pixel Palette",
          github: "pixelpalette",
          image:
            "https://raw.githubusercontent.com/D3REKZHANG/pixelpalette/master/demo.gif",
          icons: ["mdi-react", "mdi-nodejs"],
        },
        {
          name: "Cubex",
          github: "cubex",
          image:
            "https://github.com/D3REKZHANG/cubex/blob/main/demo.gif?raw=true",
          icons: ["mdi-react", "mdi-database", "mdi-nodejs"],
        },
        {
          name: "Poker Evaluator API",
          github: "poker-evaluator-api",
          image:
            "https://user-images.githubusercontent.com/20462187/133953594-2f963446-9fd0-4497-9461-a91534470540.png",
          icons: ["mdi-language-cpp"],
        },
        {
          name: "Vector Movement Demo",
          github: "VectorMovementDemo",
          image:
            "https://user-images.githubusercontent.com/20462187/131770973-44a6f867-8432-49a3-8c2f-e15dcd8af828.gif",
          icons: ["mdi-language-python"],
        },
        {
          name: "Quick Taps",
          github: "QuickTaps",
          image:
            "https://user-images.githubusercontent.com/20462187/130339969-04f09a47-e641-4961-bccb-288e4a4c2c65.png",
          icons: ["mdi-language-python"],
        },
        {
          name: "School Simulator",
          github: "SchoolSimulator",
          image:
            "https://www.pngkey.com/png/detail/130-1309645_our-story-icon-attila777gmail-vector-graphics.png",
          icons: ["mdi-language-python"],
        },
        {
          name: "Sketch League Bot",
          github: "SketchLeagueBot",
          image:
            "https://user-images.githubusercontent.com/20462187/133953816-c50c2093-911e-4d41-b533-e93f63cfb8bd.png",
          icons: ["mdi-language-python"],
        },
      ],
    };
  },
});
