
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "ProjectCard",
  props: {
    name: String,
    github: String,
    image: String,
    icons: Array,
  },
  data() {
    return {
      description: "",
    };
  },
  created() {
    axios
      .get("https://api.github.com/repos/D3REKZHANG/" + this.github)
      .then((response) => {
        this.description = response.data.description;
      });
  },
  methods: {
    openLink(): void {
      window.location.href = "https://www.github.com/D3REKZHANG/" + this.github;
    },
  },
});
