
import Vue from "vue";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      contactIcons: [
        { icon: "mdi-github", link: "https://www.github.com/D3REKZHANG" },
        {
          icon: "mdi-linkedin",
          link: "https://www.linkedin.com/in/derek-zhang-5353b5203",
        },
        {
          icon: "mdi-file-document",
          link: "https://drive.google.com/file/d/1aV8nEqqz-wmeH3T5sdMKiI8ITNJQyz73/view?usp=sharing",
        },
        { icon: "mdi-email", link: "mailto:derek.zhang0210@gmail.com" },
      ],
    };
  },
});
