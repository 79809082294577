
import Vue from "vue";


export default Vue.extend({
  name: "App",
  data() {
    return {
      scrolled: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  methods: {
    handleScroll(): void {
      this.scrolled = window.scrollY;
    },
  },
});
